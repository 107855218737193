import * as React from 'react';
import { createSvgIcon } from '../utils/createSvgIcon';

export const AccessibilityIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" viewBox="2 0 22 22" className={classes.svg} focusable="false">
      <path d="M9.04088 5.49274C9.01415 5.33173 9.00024 5.16639 9.00024 4.9978C9.00024 3.34095 10.3434 1.9978 12.0002 1.9978C13.6571 1.9978 15.0002 3.34095 15.0002 4.9978C15.0002 5.16692 14.9862 5.33277 14.9594 5.49426L17.3909 4.58718C18.6464 4.11884 20.0394 4.78922 20.4566 6.06257C20.8355 7.21879 20.2727 8.47347 19.1572 8.95935L16.0002 10.3345V13.4458L17.8833 18.9116C18.2679 20.0281 17.7509 21.2553 16.6832 21.7599C15.4514 22.342 13.9857 21.7403 13.5182 20.4606L12.0032 16.3134L10.4906 20.4632C10.0379 21.7052 8.64046 22.3183 7.41999 21.8105C6.28873 21.3397 5.71865 20.0704 6.11833 18.9121L8.00024 13.4582V10.3386L4.6935 8.88273C3.50787 8.36072 2.96841 6.97758 3.48753 5.79067C3.98732 4.64795 5.29285 4.09483 6.46145 4.53069L9.04088 5.49274ZM10.5002 4.9978C10.5002 5.61126 10.8685 6.13871 11.396 6.37114C11.7787 6.51245 12.1985 6.51556 12.5828 6.38047C13.1219 6.15306 13.5002 5.61961 13.5002 4.9978C13.5002 4.16938 12.8287 3.4978 12.0002 3.4978C11.1718 3.4978 10.5002 4.16938 10.5002 4.9978ZM10.8297 7.76087L5.93726 5.93612C5.51467 5.7785 5.04257 5.97852 4.86183 6.39175C4.67411 6.82096 4.86919 7.32113 5.29794 7.50991L9.05246 9.16296C9.32459 9.28278 9.50024 9.55204 9.50024 9.84938V13.584C9.50024 13.6672 9.48638 13.7499 9.45922 13.8286L7.53628 19.4014C7.39505 19.8107 7.5965 20.2592 7.99626 20.4256C8.42754 20.605 8.92136 20.3884 9.08134 19.9495L10.7312 15.4231C11.1637 14.2367 12.8413 14.2361 13.2746 15.4222L14.9271 19.946C15.0918 20.3968 15.6083 20.6088 16.0422 20.4037C16.4184 20.2259 16.6006 19.7936 16.4651 19.4002L14.5411 13.8157C14.5141 13.7371 14.5002 13.6545 14.5002 13.5714V9.84309C14.5002 9.54468 14.6772 9.27466 14.9507 9.15549L18.5582 7.58415C18.9642 7.40728 19.1691 6.95055 19.0312 6.52965C18.8793 6.06612 18.3722 5.82209 17.9152 5.99258L13.1342 7.77607C12.7844 7.91902 12.4015 7.9978 12.0002 7.9978C11.585 7.9978 11.1894 7.91342 10.8297 7.76087Z" />
    </svg>
  ),
  displayName: 'AccessibilityIcon',
});
